import {Route, RouteConfig} from "vue-router/types/router";

export default function ConfigureRoutes() {
    var routes: RouteConfig[] = [
        {
            path: '/',
            name: 'practices',
            component: () => import('@/views/practices/Practices.vue'),
            meta: {
                resource: 'Practices',
                action: 'View',
                pageTitle: 'Practices',
                breadcrumb: [
                    {
                        text: 'Practices',
                        active: true,
                    },
                ],
            },
        }, {
            path: '/practices',
            component: () => import('@/views/practices/Practices.vue'),
            meta: {
                resource: 'Practices',
                action: 'View',
                pageTitle: 'Practices',
                breadcrumb: [
                    {
                        text: 'Practices',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/practices-details/:id',
            name: 'practices-details',
            component: () => import('@/views/practices/PracticesDetails.vue'),
            meta: {
                resource: 'Practices',
                pageTitle: 'Practices',
                navActiveLink: 'practices',
                breadcrumb: [
                    {
                        text: 'Practices',
                        active: false,
                        to: '/practices',
                    },
                    {
                        text: 'Details',
                        active: true,
                        to: '/practices-details/:id'
                    },
                ],
            },
        },
        {
            path: '/practices-details/:id/settings',
            name: 'practices-details-settings',
            component: () => import('@/views/practices/PracticesDetails.vue'),
            meta: {
                resource: 'Practices',
                pageTitle: 'Practices',
                navActiveLink: 'practices',
                breadcrumb(route: Route) {
                    return [
                        {
                            text: 'Practices',
                            active: false,
                            to: '/practices',
                        },
                        {
                            text: 'Details',
                            active: false,
                            to: {
                                name: 'practices-details',
                                params: {id: route.params.id}
                            }
                        },
                        {
                            text: 'Settings',
                            active: true,
                        }
                    ]
                },
            },
        },
        {
            path: '/practices-details/:id/location/:itemId',
            name: 'practices-details-view-location',
            props: true,
            component: () => import('@/views/practices/location-more/LocationMoreTab.vue'),
            meta: {
                resource: 'Practices',
                pageTitle: 'Practices',
                navActiveLink: 'practices',
                breadcrumb(route: Route) {
                    return [
                        {
                            text: 'Practices',
                            active: false,
                            to: '/practices',
                        },
                        {
                            text: 'Details',
                            active: false,
                            to: {
                                name: 'practices-details',
                                params: {id: route.params.id}
                            }
                        },
                        {
                            text: 'Location',
                            active: true,
                        },
                        {
                            text: 'View',
                            active: true,
                        },
                    ]
                },
            },
        },
        {
            path: '/practices-details/:id/attachments',
            name: 'practices-details-attachments',
            component: () => import('@/views/practices/AttachmentsTab.vue'),
            meta: {
                resource: 'StorageObjects',
                pageTitle: 'Practices',
                navActiveLink: 'practices',
                breadcrumb(route: Route) {
                    return [
                        {
                            text: 'Practices',
                            active: false,
                            to: '/practices',
                        },
                        {
                            text: 'Details',
                            active: false,
                            to: {
                                name: 'practices-details',
                                params: {id: route.params.id}
                            }
                        },
                        {
                            text: 'Attachments',
                            active: true,
                        }
                    ]
                },
            },
        },
        {
            path: '/practices-details/:id/provider/:itemData',
            name: 'practices-details-view-provider',
            props: true,
            component: () => import('@/views/practices/ProviderMoreTab.vue'),
            meta: {
                resource: 'Providers',
                pageTitle: 'Practices',
                navActiveLink: 'practices',
                breadcrumb(route: Route) {
                    var id = route.params.id;
                    return [
                        {
                            text: 'Practices',
                            active: false,
                            to: '/practices',
                        },
                        {
                            text: 'Details',
                            active: false,
                            to: {
                                name: 'practices-details',
                                params: {id: id}
                            }
                        },
                        {
                            text: 'Provider',
                            active: true,
                        },
                        {
                            text: 'New',
                            active: true,
                        },
                    ]
                },
            },
        },
        {
            path: '/practices-details/:id/corporations/:itemData',
            name: 'practices-details-view-corporations',
            props: true,
            component: () => import('@/views/practices/corporations-more/CorporationsMoreTab.vue'),
            meta: {
                resource: 'Corporations',
                pageTitle: 'Practices',
                navActiveLink: 'practices',
                breadcrumb(route: Route) {
                    return [
                        {
                            text: 'Practices',
                            active: false,
                            to: '/practices',
                        },
                        {
                            text: 'Details',
                            active: false,
                            to: {
                                name: 'practices-details',
                                params: {id: route.params.id}
                            }
                        },
                        {
                            text: 'Corporations',
                            active: false,
                            to: {
                                name: 'practices-details',
                                params: {id: route.params.id}
                            }
                        },
                        {
                            text: 'View',
                            active: true,
                        },
                    ]
                },
            },
        },
        {
            path: '/practices-details/:id/profile/:itemData/type/:type',
            name: 'practices-details-view-profile',
            props: true,
            component: () => import('@/views/practices/profiles-more/ProfilesMoreTab.vue'),
            meta: {
                resource: 'Profiles',
                pageTitle: 'Practices',
                navActiveLink: 'practices',
                breadcrumb(route: Route) {
                    return [
                        {
                            text: 'Practices',
                            active: false,
                            to: '/practices',
                        },
                        {
                            text: 'Details',
                            active: false,
                            to: {
                                name: 'practices-details',
                                params: {id: route.params.id}
                            }
                        },
                        {
                            text: 'Profile',
                            active: true,
                        },
                        {
                            text: 'View',
                            active: true,
                        },
                    ]
                },
            },
        },
        {
            path: '/practices-details/:id/web-links',
            name: 'practices-details-web-links',
            component: () => import('@/views/practices/WeblinksTab.vue'),
            meta: {
                resource: 'WebLinks',
                pageTitle: 'Practices',
                navActiveLink: 'practices',
                breadcrumb(route: Route) {
                    return [
                        {
                            text: 'Practices',
                            active: false,
                            to: '/practices',
                        },
                        {
                            text: 'Details',
                            active: false,
                            to: {
                                name: 'practices-details',
                                params: {id: route.params.id}
                            }
                        },
                        {
                            text: 'Web links',
                            active: true,
                        }
                    ]
                },
            },
        },
        {
            path: '/practices-details/:id/profile/:itemData/plans-details/:planId',
            name: 'practices-details-view-profiles-more',
            component: () => import('@/views/practices/profiles-more/ProfilesPlansMoreTab.vue'),
            meta: {
                resource: 'Profiles',
                pageTitle: 'Practices',
                navActiveLink: 'practices',
                breadcrumb(route: Route) {
                    return [
                        {
                            text: 'Practices',
                            active: false,
                            to: '/practices',
                        },
                        {
                            text: 'Details',
                            active: false,
                            to: {
                                name: 'practices-details',
                                params: {id: route.params.id}
                            }
                        },
                        {
                            text: 'Profile',
                            active: true,
                        },
                        {
                            text: 'Plans Details',
                            active: true,
                        },
                    ]
                },
            },
        }
    ]

    return routes;
}
