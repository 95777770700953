import Vue from 'vue'
import router from '@/router'
// axios
import axios from 'axios'
import resolver from '@/services/refResolver'
import qs from 'qs'
import _ from 'lodash'
import store from '@/store'
import {msalPluginInstance} from "@/auth/msal-plugin";


axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true
axios.defaults.paramsSerializer = params => {

    if (params?.ColumnFilters) {

        params.ColumnFilters = JSON.stringify(params.ColumnFilters)
    }

    return qs.stringify(params)
}
axios.interceptors.request.use(rc => {
    if (rc.data)
        Object.keys(rc?.data).reduce((acc, curr) => {
            try {
                if (_.isEmpty(acc[curr]) == true) {
                    acc[curr] = null
                }
            } catch (e) {
                console.log(e)
            }

            return acc;
        }, {})

    return rc
})

const DEBUG = process.env.NODE_ENV === "development";

//lets add Bearer token to all requests
axios.interceptors.request.use(async (config) => {
    const accessToken = await msalPluginInstance.acquireToken();
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});

axios.interceptors.request.use(async (config) => {

    // if delete request, set v-model with confirmation message, if user confirms, continue with request, else cancel request
    if (config.method === 'delete') {
        var res = await Vue.confirm('Are you sure you want to delete this item?')
        if (res === false) {
            return Promise.reject('cancelled')
        }
    }


    // set loading state to true if request is not a get request
    if (config.method !== 'get') {
        store.commit('loading/SET_LOADING', true)

    } else if (config.method === 'get' && config.url?.includes('GetById')) {
        store.commit('formLoading/SET_LOADING', true)
    }


    /** In dev, intercepts request and logs it into console for dev */
    if (config.data) {
        let filledData = Object.keys(config.data).reduce((acc, curr) => {
            console.log("key: ", curr, 'value: ', acc[curr])
            if (typeof acc[curr] === 'object' && _.isEmpty(acc[curr]) === true) {
                acc[curr] = null
            }
            return acc;
        }, config.data)

        config.data = filledData;
    }


    if (DEBUG) {
        console.info("✉️ ", config);
    }
    return config;
}, (error) => {
    if (DEBUG) {
        console.error("✉️ ", error);
    }
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    if (response.data.success) {
        if (response.data.list) {
            var resolved = new resolver(response.data.list);
        }

        if (response.config.url?.includes('Update') && !response.config.url?.includes('AmdUpdateChange') ) {
            Vue.$toast.success(`Updated Successful`)
        }

        if (response.config.url?.includes('Add') && !response.config.url?.includes("CptAddOn")) {
            Vue.$toast.success(`Added Successful`)
        }
        if (response.config.url?.includes('Delete')) {
            Vue.$toast.success(`Deleted Successful`)
        }

        if (response.config.method === 'get' && response.config.url?.includes('GetById')) {
            store.commit('formLoading/SET_LOADING', false)
        }

        // set loading state to false
        store.dispatch('loading/setLoading', false)
    }


    return response;
}, error => {
    if (error.response?.status === 401) {
        localStorage.clear()
        router.replace('/login')
    } else if (error.message) {
        Vue.$toast.error(`we have encountered an error. the error message is: ${JSON.stringify(error.response?.data?.Message)}`)
        console.log(error);
    }

    // set loading state to false
    store.commit('loading/SET_LOADING', false)
    return error
})
Vue.prototype.$http = axios

export default axios
