import {Route, RouteConfig} from "vue-router/types/router";

export default function ConfigureRoutes() {
    var routes: RouteConfig[] = [
        {
            path: '/claims',
            name: 'claims',
            component: () => import('@/class-components/claims/ClaimsList.vue'),
            meta: {
                resource: 'Patients',
                action: 'View',
                pageTitle: 'claims',
                breadcrumb: [
                    {
                        text: 'claims',
                        active: true,
                    },
                ],
            },
        }
    ]

    return routes
}
