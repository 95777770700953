import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import User from '@/store/modules/user'
import LoadingModule from '@/store/modules/loading'
import reloadTabs from "@/store/modules/reloadTabs";
import formLoading from "@/store/modules/formLoading";
import reloadTables from "@/store/modules/reloadTables";
import setPractice from "@/store/modules/setPractice";

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        User,
        loading: LoadingModule,
        reloadTabs,
        formLoading,
        reloadTables,
        setPractice
    },
    strict: process.env.DEV,
})

export default store;
export const useStore = () => store;
