import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class LoadingModule extends VuexModule {
    isLoading = false;

    @Mutation
    SET_LOADING(loading: boolean) {
        this.isLoading = loading;
    }

    @Action({ commit: 'SET_LOADING' })
    setLoading(loading: boolean) {
        return loading;
    }
}
