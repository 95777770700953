import Vue from 'vue'
import VueRouter from 'vue-router'
import practice from "@/router/practice/practice";
import insurance from "@/router/insurance/insurance";
import Procedure from "@/router/procedure/procedure";
import FeeScedule from "@/router/feeScedule/feeScedule";
import Settings from "@/router/settings/settings";
import Scrubber from "@/router/scrubber/scrubber";
import reporting from "@/router/reporting/reporting";
import patient from "@/router/patient/patient";
import claims from "@/router/claims/claims";
import defineAbilitiesFor from "@/libs/acl/ability";
import {abilities} from "@/libs/acl";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    // scrollBehavior() {
    //   return { x: 0, y: 0 }
    // },
    routes: [
        ...practice(),
        ...insurance(),
        ...Procedure(),
        ...FeeScedule(),
        ...Settings(),
        ...Scrubber(),
        ...reporting(),
        ...patient(),
        ...claims(),
        {
            path: '/credentialing',
            name: 'credentialing',
            component: () => import('@/class-components/credentialing/credentialing-list.vue'),
            meta: {
                resource: 'Profiles',
                action: 'View',
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                resource: 'Auth',
                layout: 'full',
            },
        },
        {
            path: '/loginRedirect',
            name: 'loginRedirect',
            component: () => import('@/views/LoginRedirect.vue'),
            meta: {
                resource: 'Auth',
                layout: 'full',
            },
        },
        {
            path: '/pages/miscellaneous/not-authorized',
            name: 'misc-not-authorized',
            component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
            meta: {
                resource: 'Auth',
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                resource: 'Auth',
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

function userData() {
    return JSON.parse(localStorage.getItem('userData'))
}


router.beforeEach((to, from, next) => {
    const canNavigate = to.matched.some(route => {
        console.log(route.meta.action || 'View', route)


        return abilities.can(route.meta.action || 'View', route.meta.resource)
    })

    console.log('canNavigate', canNavigate)

    if (!canNavigate) {
        if (!userData())
            return next('/login')
        else
            return next({name: 'misc-not-authorized'})
    }

    next()
})

export default router
