import {Route, RouteConfig} from "vue-router/types/router";

export default function ConfigureRoutes() {
    var routes: RouteConfig[] = [
        {
            path: '/scrub-claim',
            name: 'scrub-claim',
            component: () => import('@/class-components/scrubber/scrubber.vue'),
            meta: {
                resource: 'ScrubResults',
                pageTitle: 'Scrubber',
                breadcrumb: [
                    {
                        text: 'Scrubber',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/scrub-results',
            name: 'scrub-results',
            component: () => import('@/class-components/scrubber/components/RsultsDiagram.vue'),
            meta: {
                resource: 'ScrubResults',
                pageTitle: 'Scrubber',
                breadcrumb: [
                    {
                        text: 'Scrubber',
                        active: true,
                    },
                ],
            },
        },
    ]

    return routes;
}
