import {RouteConfig} from "vue-router/types/router";

export default function ConfigureRoutes() {
    var routes: RouteConfig[] = [


        {
            path: '/general-rules',
            name: 'general-rules',
            component: () => import('@/views/settings/GeneralRules.vue'),
            meta: {
                resource: 'ScrubberGeneralSettings',
                pageTitle: 'Settings General Rules',
                breadcrumb: [
                    {
                        text: 'Settings General Rules',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/lists-options/',
            name: 'lists-options',
            component: () => import('@/views/settings/ListsOptions.vue'),
            meta: {
                resource: 'ListOptions',
                pageTitle: 'Settings Lists Options',
                breadcrumb: [
                    {
                        text: 'Settings Lists Options',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/user-settings',
            name: 'user-settings',
            component: () => import('@/views/settings/Users.vue'),
            meta: {
                resource: 'Users',
                pageTitle: 'Settings User Lists',
                breadcrumb: [
                    {
                        text: 'Settings User Lists',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/user-settings/add-users',
            name: 'add-users',
            component: () => import('@/views/settings/AddUsers.vue'),
            meta: {
                resource: 'Users',
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Settings User Lists',
                        active: false,
                        to: '/user-settings',
                    },
                    {
                        text: 'Add User',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/user-settings/update-users/:user',
            name: 'update-users',
            component: () => import('@/views/settings/AddUsers.vue'),
            meta: {
                resource: 'Users',
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Settings User Lists',
                        active: false,
                        to: '/user-settings',
                    },
                    {
                        text: 'Update User',
                        active: true,
                    },
                ],
            },
        },
    ]

    return routes;
}
