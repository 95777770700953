import Vue from 'vue'
import {abilitiesPlugin} from '@casl/vue'
import ability from './ability'
import defineAbilitiesFor from "./ability";

function userData() {
    return JSON.parse(localStorage.getItem('userData'))
}

export const abilities = defineAbilitiesFor(userData())


Vue.use(abilitiesPlugin, abilities)
