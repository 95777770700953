import {Route, RouteConfig} from "vue-router/types/router";

export default function ConfigureRoutes() {
    var routes: RouteConfig[] = [
        {
            path: '/report-viewer/:reportName',
            name: 'report-viewer',
            props: true,
            component: () => import('@/class-components/reporting/report-viewer.vue'),
            meta: {
                resource: 'Reports',
            },
        },
        {
            path: '/report-designer',
            name: 'report-designer',
            component: () => import('@/class-components/reporting/ReportDesigner.vue'),
            meta: {
                resource: 'Reports',
                action: 'Create',
                layout: {
                    navbar: {
                        type: 'hidden',
                    },
                    menu: {
                        hidden: true,
                        isCollapsed: true,
                    }
                },
            },
        },

    ]

    return routes;
}
