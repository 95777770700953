import * as _ from 'lodash'

export interface IBJ {
    $id: number,
    $ref: number,
    [key: string]: any | undefined | null
  }
  
  export default class resolver {
    
  
    public resovedRefs = new Map();
    public refObjects=new Map();
  
    constructor(object:object) {
      this.refFinder(object, object as IBJ)
  
      this.refObjects.forEach((value,key,map)=>{
        _.extend(key,value)
        
        key.$ref=undefined
      })
    }
  
  
    refFinder(source: object, object: IBJ, level: number = 50) {
     
      level--;
      if (object && object.hasOwnProperty('$ref')) {
        this.assighnValue(object, source, object.$ref)
      }
      else if (level != 0) {
  
        for (let subkey in object) {
          if (typeof object[subkey] !== "object") {
            continue;
          }
          if (typeof object[subkey] === "object") {
            if (!_.isEmpty(object[subkey]))
              this.refFinder(source, object[subkey], level)
          }
          else if (typeof object[subkey] === typeof []) {
            (object[subkey] as IBJ[]).forEach((x, c) => {
              if (!_.isEmpty(x))
                this.refFinder(source, x, level)
            })
          }
          else {
            this.refFinder(source, object[subkey], level)
            continue;
          }
  
        }
  
      }
  
  
    }
    assighnValue(object: IBJ, source: object, refNumber: number) {
  
      var value = this.resovedRefs.get(refNumber)
      if (!value) {
        value = this.findNested(source as IBJ,'$id', refNumber)
        this.resovedRefs.set(refNumber, value)
      }
      this.refObjects.set(object,value)
    }
    findNested(obj: { [x: string]: any; }, key: string | number, value: any):any {
  
      // Base case
      if (obj[key] === value) {
        return obj;
      } else {
        var keys = Object.keys(obj); // add this line to iterate over the keys
    
        for (var i = 0, len = keys.length; i < len; i++) {
          var k = keys[i]; // use this key for iteration, instead of index "i"
    
          // add "obj[k] &&" to ignore null values
          if (obj[k] && typeof obj[k] == 'object') {
            var found = this.findNested(obj[k], key, value);
            if (found) {
              // If the object was found in the recursive call, bubble it up.
              return found;
            }
          }
        }
      }
    }
  
    
    
  }

